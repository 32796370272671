<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-layout justify-center align-center>
          <v-btn depressed color="blue" dark :loading="uploading" :disabled="uploading" @click.stop="onPickFile">
            <template v-slot:loader>
              <span>{{ $t("global.loading_message") }}</span>
              <v-progress-circular class="ml-2" indeterminate :size="22" :width="2" />
            </template>
            {{ $t("buttons.file_upload") }}
            <v-icon right dark>mdi-cloud_upload</v-icon>
          </v-btn>
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, image/*"
            @change="onFilePicked"
          />
        </v-layout>
      </v-col>
    </v-row>

    <v-row v-if="uploadedFiles.length">
      <v-col cols="12">
        <v-subheader class="px-0">
          {{ $t("global.uploaded_files") }}
          <v-divider inset />
        </v-subheader>
      </v-col>
      <v-col cols="12">
        <v-slide-x-transition mode="out-in" class="mb-2 py-0" group tag="div">
          <div v-for="(item, index) in uploadedFiles" :key="index">
            <v-card flat color="grey lighten-4" class="mb-2">
              <v-list-item avatar>
                <v-list-item-avatar>
                  <v-icon>mdi-paperclip</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $helpers.humanFileSize(item.size) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn flat icon @click.stop="onRemoveFile(item.url, index)">
                    <v-icon :size="20">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </div>
        </v-slide-x-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  /**
   * @project globme-idenfit
   * @developer Halil Kılıçarslan
   * @description FileUploadPicker page logic
   * @date 17.01.2020
   */

  export default {
    name: "FileUploadPicker",
    props: {
      value: [String, Object, Array],
      storageType: {
        type: String,
        required: true
      },
      saved: {
        type: Boolean,
        required: true,
        default: () => false
      }
    },
    data: vm => ({
      uploading: false,
      uploadedFiles: [],
      selectedUploadFile: null
    }),
    methods: {
      onPickFile() {
        this.$refs.fileInput.click();
      },
      onFilePicked(e) {
        this.selectedUploadFile = e.target.files[0];
        if (this.selectedUploadFile !== undefined) {
          this.validateFile(this.selectedUploadFile)
            .then(valid => {
              if (valid) this.uploadLeaveRequestFile(this.selectedUploadFile);
            })
            .catch(error => {
              this.selectedUploadFile = null;
              this.$refs.fileInput.value = null;
              let message = {
                text: "Geçersiz dosya formatı",
                type: "error",
                color: "error"
              };
              this.$eventBus.$emit("snack-bar-notification", message);
            });
        } else {
          this.selectedUploadFile = null;
          this.$refs.fileInput.value = null;
        }
      },
      validateFile(file) {
        return new Promise((resolve, reject) => {
          const validFormats = [
            "application/msword",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "application/pdf",
            "image/png",
            "image/jpg",
            "image/jpeg"
          ];
          const isFormalExtension = validFormats.includes(file.type);
          const fileSize = file.size / 1024 / 1024 < 3;
          if (!isFormalExtension) reject({ message: "file_format_error" });
          if (!fileSize) reject({ message: "less_than_2_mb_error" });
          if (isFormalExtension && fileSize) resolve(true);
        });
      },
      uploadLeaveRequestFile(file) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("storageType", this.storageType);
        this.uploading = true;
        this.$serviceContext.storageService
          .upload(formData, {
            onUploadProgress: progressEvent => {}
          })
          .then(({ data }) => {
            if (data.data) {
              this.value.push(data.data);
              this.uploadedFiles.push({
                fileName: this.selectedUploadFile.name,
                size: this.selectedUploadFile.size,
                type: this.selectedUploadFile.type,
                url: data.data
              });
            }
          })
          .catch(e => {
            let message = {
              text: "Dosya yüklenemedi",
              type: "error",
              color: "error"
            };
            this.$eventBus.$emit("snack-bar-notification", message);
          })
          .then(() => {
            this.$refs.fileInput.value = "";
            this.uploading = false;
            this.selectedUploadFile = null;
          });
      },
      onRemoveFile(url, index) {
        if (url) {
          this.$serviceContext.storageService
            .delete(this.storageType, url)
            .then(({ data }) => {
              this.value = this.value.splice(index, 1);
              this.uploadedFiles.splice(index, 1);
            })
            .catch(e => {
              let message = {
                text: "Dosya silinemedi",
                type: "error",
                color: "error"
              };
              this.$eventBus.$emit("snack-bar-notification", message);
            })
            .then(() => {});
        }
      },
      removeAllUploadedFiles() {
        this.$serviceContext.storageService
          .multipleDelete(this.storageType, this.value)
          .then(({ data }) => {})
          .catch(e => {
            let message = {
              text: "Dosyalar silinemedi",
              type: "error",
              color: "error"
            };
            this.$eventBus.$emit("snack-bar-notification", message);
          })
          .then(() => {});
      }
    }
  };
</script>

<style scoped></style>
